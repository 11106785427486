import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, filter, share } from 'rxjs/operators';

import { AppService } from '@logic-suite/shared/app.service';

import { CustomerService } from '../../access/customer.service';
import { Menu } from './menu.model';

@Injectable()
export class MenuService implements OnDestroy {
  subscriptions: Subscription[] = [];
  allowedItems$ = new BehaviorSubject<Menu[] | null>(null);

  constructor(
    private http: HttpClient,
    private customer: CustomerService,
    private app: AppService,
  ) {
    this.subscriptions.push(
      combineLatest([this.app.application$, this.customer.selectedCustomer$])
        .pipe(
          filter(([app, current]) => !!current?.customerID),
          switchMap(([app, current]) =>
            this.http.get<Menu[]>('/api/shared/Menu', {
              params: {
                customerID: '' + current?.customerID,
                applicationID: '' + app,
              },
            }),
          ),
          share(),
        )
        .subscribe((res) => this.allowedItems$.next(res)),
    );
  }

  getMenu(): Observable<Menu[] | null> {
    return this.allowedItems$;
  }

  ngOnDestroy() {
    this.subscriptions.filter((s) => !!s && !s.closed).forEach((s) => s.unsubscribe());
  }
}
