import { Component, HostBinding, Input, OnInit, inject, signal } from '@angular/core';
import { filter } from 'rxjs/operators';

import { LanguageService } from '@logic-suite/shared/i18n/language.service';
import { AccessService } from '../../access/access.service';

import { Menu, MenuGroup } from './menu.model';
import { MenuService } from './menu.service';

// In order to animate gradients, we need to use CSS Houdini.
if ('CSS' in window && 'registerProperty' in CSS) {
  (CSS as { [key: string]: any })['registerProperty']({
    name: '--colorStop',
    syntax: '<color>',
    inherits: false,
    initialValue: 'transparent',
  });
  (CSS as { [key: string]: any })['registerProperty']({
    name: '--color2-stop',
    syntax: '<color>',
    inherits: false,
    initialValue: 'transparent',
  });
  (CSS as { [key: string]: any })['registerProperty']({
    name: '--color3-stop',
    syntax: '<color>',
    inherits: false,
    initialValue: 'transparent',
  });
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: false,
})
export class MenuComponent implements OnInit {
  private language = inject(LanguageService);
  private menu = inject(MenuService);
  private access = inject(AccessService);

  availableLanguages = this.language.supportedLanguages;
  @Input() forceEnv = 'prod';

  @HostBinding('class.screen-only') screenOnly = true;
  @HostBinding('class.dev')
  get isDev() {
    return location.hostname.indexOf('dev') > -1 || this.forceEnv === 'dev';
  }

  @HostBinding('class.test')
  get isTest() {
    return location.hostname.indexOf('test') > -1 || this.forceEnv === 'test';
  }

  get returnState() {
    const query = this.access.packQuery(this.access.getQueryParams());

    return this.access.generateState({
      returnRoute: `${location.pathname}${query.length ? '?' + query : ''}`,
    });
  }
  config = signal<MenuGroup[]>([]);

  ngOnInit() {
    this.menu
      .getMenu()
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        const widgets = res || ([] as Menu[]);
        const groups = widgets.reduce((prev, acc) => prev.add(acc.groupName), new Set<string>());
        this.config.set(
          Array.from(groups).map((g) => ({
            name: g,
            items: widgets.filter((w) => w.groupName === g).map((w) => w.componentName),
          })),
        );
      });
  }

  setLanguage($event: string) {
    return this.language.change($event);
  }

  preventClose($event: MouseEvent) {
    $event.stopPropagation();
  }

  preventDefault($event: MouseEvent) {
    $event.preventDefault();
  }
}
